import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, ButtonGroup } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { baseUrl } from 'helpers/requests';

const Combined = () => {
  const [selectedSource, setSelectedSource] = useState('source_openai');
  const [thresholdToggle, setThresholdToggle] = useState(false);
  const [nonZeroToggle, setNonZeroToggle] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartData, setChartData] = useState({
    sources: [],
    biases: [],
    dataMatrix: [],
    maxValue: 0
  });
  const [articlesPerSource, setArticlesPerSource] = useState([]);
  const [articlesPerCategory, setArticlesPerCategory] = useState([]);

  useEffect(() => {
    fetchBiasData();
  }, [selectedSource, thresholdToggle, nonZeroToggle, startDate, endDate]);

  const fetchBiasData = async () => {
    try {
      const queryParams = new URLSearchParams({
        startDate,
        endDate
      });
      const response = await fetch(
        `${baseUrl}/api/articles-with-bias?${queryParams.toString()}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('access')).token
            }`
          }
        }
      );
      const { data } = await response.json();

      // Process bias data
      const biasData = data.biasCounts[selectedSource];
      const biasThresholds = data.biasData;

      const sourcesSet = new Set();
      const allBiasTypes = Object.keys(biasThresholds).sort(); // All bias types
      const relevantBiasTypes = new Set();
      const biasCounts = {};

      Object.keys(biasData || {}).forEach(biasType => {
        const sourceCounts = biasData[biasType];
        const threshold = biasThresholds[biasType]?.threshold || 0;

        Object.keys(sourceCounts).forEach(source => {
          const count = sourceCounts[source];
          const countCondition = thresholdToggle
            ? count > threshold
            : count >= 0;

          if (countCondition) {
            sourcesSet.add(source);
            relevantBiasTypes.add(biasType);

            if (!biasCounts[biasType]) biasCounts[biasType] = {};
            biasCounts[biasType][source] = count;
          }
        });
      });

      const sources = Array.from(sourcesSet).sort();
      const biases = nonZeroToggle
        ? Array.from(relevantBiasTypes).sort()
        : allBiasTypes;

      const dataMatrix = biases.map(bias =>
        sources.map(source => biasCounts[bias]?.[source] || 0)
      );

      const maxValue = Math.max(...dataMatrix.flat());

      // Set chart data
      setChartData({
        sources,
        biases,
        dataMatrix,
        maxValue
      });

      // Process bar chart data
      setArticlesPerSource(
        Object.entries(data.articlesPerSource)
          .map(([key, value]) => ({
            name: key,
            value
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      setArticlesPerCategory(
        Object.entries(data.articlesPerCategory)
          .map(([key, value]) => ({
            name: key,
            value
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch (error) {
      console.error('Error fetching bias data:', error);
    }
  };

  const getChartOptions = () => ({
    tooltip: {
      position: 'top'
    },
    grid: {
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: chartData.sources,
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45
      }
    },
    yAxis: {
      type: 'category',
      data: chartData.biases
    },
    visualMap: {
      min: 0,
      max: chartData.maxValue,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '0%'
    },
    series: [
      {
        type: 'heatmap',
        data: chartData.dataMatrix.flatMap((row, i) =>
          row.map((value, j) => [j, i, value])
        ),
        label: {
          show: true
        }
      }
    ]
  });

  const getBarChartOptions = (data, title) => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    title: {
      text: title,
      left: 'center'
    },
    xAxis: {
      type: 'category',
      data: data.map(item => item.name),
      axisLabel: {
        interval: 0,
        rotate: 45
      }
    },
    grid: {
      containLabel: true
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: data.map(item => item.value),
        type: 'bar'
      }
    ]
  });

  return (
    <Container>
      <Row className="mb-4">
        <Col className="md-12 d-flex align-items-center justify-content-between">
          <Card className="w-100">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>Number of Articles by Source</Card.Header>
            <Card.Body>
              <ReactECharts
                option={getBarChartOptions(articlesPerSource, '')}
                style={{ height: '300px', width: '100%' }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>Number of Articles by Category</Card.Header>
            <Card.Body>
              <ReactECharts
                option={getBarChartOptions(articlesPerCategory, '')}
                style={{ height: '300px', width: '100%' }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="biasSourceSelect">
                    <Form.Select
                      value={selectedSource}
                      onChange={e => setSelectedSource(e.target.value)}
                    >
                      <option value="source_openai">Source Bias OpenAI</option>
                      <option value="source_anthropic">
                        Source Bias Anthropic
                      </option>
                      <option value="iterationOne_openai">
                        Iteration One Bias OpenAI
                      </option>
                      <option value="iterationOne_anthropic">
                        Iteration One Bias Anthropic
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col
                  md={8}
                  className="d-flex align-items-center justify-content-between"
                >
                  <ButtonGroup>
                    <Form.Check
                      type="switch"
                      id="thresholdToggle"
                      label="Apply Threshold"
                      checked={thresholdToggle}
                      onChange={e => setThresholdToggle(e.target.checked)}
                      className="me-3"
                    />
                    <Form.Check
                      type="switch"
                      id="nonZeroToggle"
                      label="Show Non-Zero Bias Types"
                      checked={nonZeroToggle}
                      onChange={e => setNonZeroToggle(e.target.checked)}
                    />
                  </ButtonGroup>
                </Col>
              </Row>
              <ReactECharts
                option={getChartOptions()}
                style={{ height: '1000px', width: '100%' }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Combined;
