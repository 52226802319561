import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Spinner, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  selectArticles,
  selectArticleStatus,
  selectArticleError
} from 'redux/articleSlice';
import { baseUrl, fetchArticleById } from 'helpers/requests';

const Post = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const status = useSelector(selectArticleStatus);
  const error = useSelector(selectArticleError);
  const [article, setArticle] = useState(null);
  const [relatedArticles] = useState([]);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetchArticleById(id);
        setArticle(response.data);
        // dispatch(getArticles({ category: response.data.categories[0], limit: 5 })); // Fetch related articles
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };
    fetchArticle();
  }, [id, dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      // setRelatedArticles(articles.filter(art => art._id !== id));
    }
  }, [status, articles, id]);

  const formatDate = date => {
    return moment(date).format('Do MMMM YYYY');
  };

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        {status === 'loading' || !article ? (
          <Spinner animation="border" />
        ) : status === 'failed' ? (
          <div>Error: {error}</div>
        ) : (
          <Row>
            <Col md={8}>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${baseUrl}/images/` + article.image}
                  // onError={(e) => { e.target.onerror = null; e.target.src = "default-image.jpg"; }}
                  alt={article.imageFilename}
                />
                <Card.Body>
                  <Card.Title>{article.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {article.seoTitle}
                  </Card.Subtitle>
                  <Card.Text>
                    <small>{article.imageCaption}</small>
                    <div className="mt-3">{article.summary}</div>
                    <div
                      dangerouslySetInnerHTML={{ __html: article.body }}
                    ></div>
                  </Card.Text>
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      <Badge bg="secondary">{article.userId.username}</Badge>
                      <div>{formatDate(article.createdAt)}</div>
                    </div>
                    <div>
                      {article.categories.map((category, index) => (
                        <Badge bg="primary" className="me-1" key={index}>
                          {category}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <div className="mt-4">
                <h5>Reference Links:</h5>
                <ul>
                  {article.referenceArticles.map((link, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <h5>Related Articles</h5>
              {relatedArticles.map(relArticle => (
                <Card className="mb-4" key={relArticle._id}>
                  <Link to={`/post/${relArticle._id}`}>
                    <Card.Img
                      variant="top"
                      src={relArticle.image}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = 'default-image.jpg';
                      }}
                      alt={relArticle.imageFilename}
                    />
                  </Link>
                  <Card.Body>
                    <Link to={`/post/${relArticle._id}`}>
                      <Card.Title>{relArticle.title}</Card.Title>
                    </Link>
                    <Card.Text>{relArticle.summary}</Card.Text>
                    <div className="d-flex justify-content-between">
                      <div>
                        <Badge bg="secondary">
                          {relArticle.userId.username}
                        </Badge>
                        <div>{formatDate(relArticle.createdAt)}</div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default Post;
