import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Spinner, Badge, Button, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { baseUrl } from 'helpers/requests';
import CustomPagination from 'components/utilities/CustomPagination';
import {
  selectArticleStatus,
  selectArticles,
  selectArticleError,
  getArticles
} from 'redux/articleSlice';

const biasTable = {
  'framing bias': { threshold: 0.33 },
  'tone bias': { threshold: 0.33 },
  sensationalism: { threshold: 0.23 },
  'false balance': { threshold: 0.17 },
  'omission bias': { threshold: 0.23 },
  'narrative bias': { threshold: 0.33 },
  'linguistic bias': { threshold: 0.27 },
  'text-level context bias': { threshold: 0.27 },
  'cognitive bias': { threshold: 0.33 },
  'hate speech': { threshold: 0.1 },
  'racial bias': { threshold: 0.1 },
  'fake news': { threshold: 0.1 },
  'gender bias': { threshold: 0.17 },
  'political bias': { threshold: 0.33 },
  'negative sentiment': { threshold: 0.33 },
  classism: { threshold: 0.17 },
  stereotyping: { threshold: 0.17 },
  racism: { threshold: 0.1 },
  ageism: { threshold: 0.17 },
  'religious bias': { threshold: 0.17 },
  'confirmation bias': { threshold: 0.27 },
  'recency bias': { threshold: 0.33 },
  'authority bias': { threshold: 0.27 },
  'bandwagon effect': { threshold: 0.27 },
  'selection bias': { threshold: 0.23 },
  'survivorship bias': { threshold: 0.23 },
  'availability bias': { threshold: 0.27 },
  'anchoring bias': { threshold: 0.27 },
  'geographic bias': { threshold: 0.27 },
  'temporal bias': { threshold: 0.33 },
  'rhetoric bias': { threshold: 0.2 },
  'statement bias': { threshold: 0.17 },
  'ideology bias': { threshold: 0.23 },
  argumentation: { threshold: 0.2 },
  blaming: { threshold: 0.13 },
  demonization: { threshold: 0.1 },
  emotions: { threshold: 0.23 },
  fabulation: { threshold: 0.1 },
  'fear-mongering': { threshold: 0.13 },
  labeling: { threshold: 0.17 },
  relativization: { threshold: 0.2 }
};

const BiasReport = () => {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const status = useSelector(selectArticleStatus);
  const error = useSelector(selectArticleError);
  const { pages } = useSelector(state => state.article);

  const [currentPage, setCurrentPage] = useState(1);
  const [hideZeroValues, setHideZeroValues] = useState(false);
  const [showSourceText, setShowSourceText] = useState(false);

  useEffect(() => {
    dispatch(getArticles({ page: currentPage, limit: 1 }));
  }, [dispatch, currentPage]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const formatDate = date => moment(date).format('Do MMMM');

  const getChartOptions = article => {
    if (!article || !article.biasData) return {};

    const biasDataSources = {
      'Source Bias: OpenAI': article.biasData.source.openai.bias_levels || {},
      'Source Bias: Anthropic':
        article.biasData.source.anthropic.bias_levels || {},
      'Iteration One Bias: OpenAI':
        article.biasData.iterationOne.openai.bias_levels || {},
      'Iteration One Bias: Anthropic':
        article.biasData.iterationOne.anthropic.bias_levels || {},
      'Iteration Two Bias: OpenAI':
        article.biasData.iterationTwo?.openai?.bias_levels || {},
      'Iteration Two Bias: Anthropic':
        article.biasData.iterationTwo?.anthropic?.bias_levels || {}
    };

    // Get bias types and sort alphabetically
    const biasTypes = Object.keys(biasTable).sort();

    // Filter out biases with all zero values across sources if hideZeroValues is true
    const filteredBiasTypes = biasTypes.filter(type =>
      hideZeroValues
        ? Object.values(biasDataSources).some(source => source[type] > 0)
        : true
    );

    // Prepare data for each source
    const seriesData = Object.keys(biasDataSources).reduce((acc, key) => {
      acc[key] = filteredBiasTypes.map(type => biasDataSources[key][type] || 0);
      return acc;
    }, {});

    // Threshold data
    const thresholds = filteredBiasTypes.map(
      type => biasTable[type]?.threshold || null
    );

    // Colors for each series
    const seriesColors = {
      'Source Bias: OpenAI': '#164863',
      'Source Bias: Anthropic': '#603F26',
      'Iteration One Bias: OpenAI': '#427D9D',
      'Iteration One Bias: Anthropic': '#6C4E31',
      'Iteration Two Bias: OpenAI': '#9BBEC8',
      'Iteration Two Bias: Anthropic': '#FFDBB5'
    };

    return {
      title: {
        text: 'Bias Score Comparison',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredBiasTypes,
        axisLabel: {
          interval: 0,
          rotate: 45
        }
      },
      yAxis: {
        type: 'value',
        name: 'Bias Score'
      },
      legend: {
        data: [...Object.keys(seriesData), 'Thresholds'],
        bottom: 0
      },
      series: [
        // Add each source as a series
        ...Object.keys(seriesData).map(source => ({
          name: source,
          type: 'bar',
          data: seriesData[source],
          itemStyle: { color: seriesColors[source] }
        })),
        // Threshold line
        {
          name: 'Thresholds',
          type: 'line',
          data: thresholds,
          lineStyle: {
            type: 'dashed',
            color: '#AF1740' // Threshold line color
          },
          symbol: 'circle',
          symbolSize: 6
        }
      ]
    };
  };

  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
              >
                Previous
              </Button>
            </div>
            <div className="col-auto">
              <CustomPagination
                pages={pages}
                currentPage={currentPage}
                handlePageChange={page => handlePageChange(page)}
              />
            </div>
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= pages}
              >
                Next
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body className="overflow-hidden px-lg-6">
          <div className="container">
            {status === 'loading' ? (
              <div
                style={{ height: '480px' }}
                className="d-flex justify-content-center v-center"
              >
                <Spinner animation="border" />
              </div>
            ) : status === 'failed' ? (
              <div>Error: {error}</div>
            ) : (
              <div>
                <Form.Check
                  type="checkbox"
                  label="Hide Zero Values"
                  checked={hideZeroValues}
                  onChange={e => setHideZeroValues(e.target.checked)}
                  className="mb-3"
                />

                {/* Graph Section */}
                {articles.length > 0 && articles[0].biasData ? (
                  <div className="row mb-4">
                    <Col>
                      <ReactECharts
                        option={getChartOptions(articles[0])}
                        style={{ height: '400px', width: '100%' }}
                      />
                    </Col>
                  </div>
                ) : (
                  <p>No bias data available for the current article.</p>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body className="overflow-hidden px-lg-6">
          <div className="row mb-4">
            {articles.map(article => (
              <Row className="mb-4" key={article._id}>
                <Col md={12} lg={4} className="d-flex mb-4">
                  <Card>
                    {article.image && article.image !== '' ? (
                      <Card.Img
                        variant="top"
                        src={`${baseUrl}/images/` + article.image}
                        alt={article.imageFilename}
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src={article.images[0]}
                        alt={article.imageFilename}
                      />
                    )}
                    <Card.Body className="d-flex flex-column">
                      <div className="d-flex justify-content-between mb-3">
                        <div className="text-small d-flex">
                          {article.categories.map((category, index) => (
                            <div className="me-1" key={index}>
                              <Link to="#">{category}</Link>
                            </div>
                          ))}
                        </div>
                        <Badge bg="primary-alt" text="primary">
                          {formatDate(article.createdAt)}
                        </Badge>
                      </div>
                      <Card.Text className="flex-grow-1 text-dark">
                        {article.isTweet && (
                          <>
                            Source:{' '}
                            <Link
                              target="_blank"
                              to={`https://x.com/${article.tweetAccount.twitterId}`}
                            >
                              @{article.tweetAccount.twitterId}
                            </Link>
                            <hr />
                            <strong>Original Tweet:</strong>
                            <p>{article.tweetId.tweetJson.text}</p>
                          </>
                        )}
                        <hr />
                        <Form.Check
                          type="checkbox"
                          label="Show Source Text"
                          checked={showSourceText}
                          onChange={e => setShowSourceText(e.target.checked)}
                          className="mb-3"
                        />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12} lg={8} className="d-flex mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Text className="flex-grow-1">
                        {showSourceText ? (
                          <div>{article.sourceText}</div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: article.body }}
                          ></div>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default BiasReport;
