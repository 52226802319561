import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBiasCheckers } from 'helpers/requests';

export const getBiasCheckers = createAsyncThunk(
  'biasChecker/bias-checker',
  async data => {
    const response = await fetchBiasCheckers(data);
    return response;
  }
);

const biasCheckerSlice = createSlice({
  name: 'biasChecker',
  initialState: {
    biasCheckers: [],
    biasChecker: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBiasCheckers.pending, state => {
        state.status = 'loading';
      })
      .addCase(getBiasCheckers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.biasCheckers = action.payload.biasCheckers;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getBiasCheckers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectBiasCheckers = state => state.biasChecker.biasCheckers;
export const selectBiasCheckerStatus = state => state.biasChecker.status;
export const selectBiasCheckerError = state => state.biasChecker.error;

export default biasCheckerSlice.reducer;
