import { createBiasChecker } from 'helpers/requests';
import React, { useState } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TextareaModal = ({ show, handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      await createBiasChecker({ sourceText: inputValue });
      handleClose();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Submit Your Text</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="textareaInput">
            <Form.Label>Enter your text</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Type something..."
            />
          </Form.Group>
        </Form>
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isLoading || !inputValue.trim()}
        >
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TextareaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default TextareaModal;
