import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({ pages, currentPage, handlePageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisibleButtons = 5; // Adjust this for more/less buttons

    // Always show the first page
    pageNumbers.push(
      <Pagination.Item
        key={1}
        active={currentPage === 1}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );

    // Show dots if necessary
    if (currentPage > maxVisibleButtons) {
      pageNumbers.push(
        <Pagination.Ellipsis
          key="start-dots"
          onClick={() => handlePageChange(currentPage - 5)}
        />
      );
    }

    // Generate visible range of page numbers
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(pages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={currentPage === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Show dots if necessary
    if (currentPage < pages - maxVisibleButtons) {
      pageNumbers.push(
        <Pagination.Ellipsis
          key="end-dots"
          onClick={() => handlePageChange(currentPage + 5)}
        />
      );
    }

    // Always show the last page
    if (pages > 1) {
      pageNumbers.push(
        <Pagination.Item
          key={pages}
          active={currentPage === pages}
          onClick={() => handlePageChange(pages)}
        >
          {pages}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  return <Pagination>{renderPageNumbers()}</Pagination>;
};

CustomPagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

export default CustomPagination;
